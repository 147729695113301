import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Marietas = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Marietas | HB Privileged',
      title: 'BAHÍA ALEGRE MARIETAS',
      subtitle: 'TOUR OVERVIEW',
      text: "Start this wonderful adventure at the Marina Vallarta by boarding one of the nicest catamarans available in our port. Cruise along Banderas Bay surrounded by marine wildlife on your way to the enchanted Marietas Islands, a natural waterpark protected by the mexican goverment. Enjoy a nice breakfast and softdrinks and prepare to snorkel, kayak or paddle board around the islands. On your way back, put your talent at work in the karaoke party or enjoy the full open bar on deck with the breeze in your hair. If the conditions of the ocean are right, you may swim to the famous Hidden Beach! A special bracelet given by the mexican authorities is not included, but is available for purchase on board.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Marietas | HB Privileged',
      title: 'BAHÍA ALEGRE MARIETAS',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Empieza esta maravillosa aventura en la Marina Vallarta abordando uno de los mejores catamaranes disponibles en nuestro puerto. Navega a través de la Bahía de Banderas rodeado de fauna marina rumbo a las encantadas Islas Marietas, un parque natural protegido por el gobierno mexicano. Disfruta de un buen desayuno y bebidas no alcohólicas mientras te preparas para snorkelear, hacer kayac o tabla de remo alrededor de las islas. De regreso, prueba tu talento en la fiesta karaoke o disfruta de la barra libre en la cubierta con la brisa en tu cabello. Si las condiciones marítimas lo permiten, puedes nadar a la famosa Playa Escondida. Un brazalete especial emitido por las autoridades mexicanas no está incluído, pero está disponible abordo para obtenerlo.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/marietas.jpg`,
    `${BASE_URL}/marietas.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/bahia.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Marietas;